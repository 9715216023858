@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Noto Sans Thai';
  font-weight: 400;
  src: url('/fonts/NotoSansThai-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Thai';
  font-weight: 500;
  src: url('/fonts/NotoSansThai-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Thai';
  font-weight: 600;
  src: url('/fonts/NotoSansThai-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Thai';
  font-weight: 700;
  src: url('/fonts/NotoSansThai-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'DB-Ozone-X';
  font-weight: bold;
  src: url('/fonts/DB-Ozone-X-Bd-v3.2.1.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'DB-Ozone-X';
  src: url('/fonts/DB-Ozone-X-Med-v3.2.1.ttf') format('truetype');
  font-display: swap;
}

:root {
  @apply font-noto-sans;
}

html,
body,
#__next {
  height: 100%;
}

a {
  cursor: pointer;
}

.loader {
  border-right-color: transparent;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 9999px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
}

input[type='range']::-webkit-slider-thumb:active {
  border: 1px solid #a80030;
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 9999px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
}

input[type='range']::-moz-range-thumb:active {
  border: 1px solid #a80030;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.slick-slide {
  height: auto;
}

.slick-slide > div {
  display: flex;
}
